import React from "react";
import Footer from "../../components/Main/Footer";
import Logo from "../../components/Onboard/Logo";

const ShippingAndDelivery = () => {
  return (
    <div className="legal">
      <Logo />
      <h2 className="title">Shipping And Delivery Policy</h2>
      <h3>Delivery Charges</h3>
      <p>
        Delivery charges may vary based on the distance of the delivery location
        from the restaurant.
      </p>
      <h3>Delivery Methods</h3>
      <p>
        The restaurant offers delivery services where the food is prepared and
        delivered by the restaurant's driver to the customer's address.
      </p>
      <h3>Lead Times</h3>
      <p>
        The agreed time for delivery is communicated to the customer, and the
        restaurant will make all necessary efforts to ensure timely delivery.
      </p>
      <h3>Cancellation Policy</h3>
      <p>
        Customers can cancel their order within a specified time frame, and the
        restaurant may have a cancellation fee for late cancellations.
      </p>
      <h3>Order Accuracy</h3>
      <p>
        The restaurant will make all necessary diligences to ensure that the
        information in relation to the online ordering is accurate and reliable.
      </p>
      <h3>Responsibilities of Customers</h3>
      <p>
        Customers are responsible for providing the correct delivery address and
        contact information. They are also expected to be present to accept the
        delivery at the agreed time and place.
      </p>
      <h3>Termination and Refunds</h3>
      <p>
      The restaurant may reserve the right to suspend the use of the online ordering application, and customers may need to contact the restaurant directly to settle any payment dispute or refund claim.
      </p>
      <Footer />
    </div>
  );
};

export default ShippingAndDelivery;
