import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// https://www.perplexity.ai/search/979df2aa-de61-4729-91cd-862eddf83679?s=u
const NetworkStatus = () => {
  const location = useLocation().pathname.split("/");

  const [status, setStatus] = useState(navigator.onLine ? "online" : "offline");

  useEffect(() => {
    function handleOnline() {
      setStatus("online");
    }

    function handleOffline() {
      setStatus("offline");
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const [showOnline, setShowOnline] = useState(navigator.onLine);

  useEffect(() => {
    if (showOnline)
      setTimeout(() => {
        setShowOnline(false);
      }, 3000);
  }, [showOnline]);

  useEffect(() => {
    if (navigator.onLine) setShowOnline(true);
  }, []);

  const Status = ({ isOnline }) => {
    return (
      <div
        className={`fixed ${
          location[1] !== "main" ? "bottom-0" : "bottom-14"
        } lg:bottom-0 z-30 w-full flex justify-center space-x-4 py-2 text-white font-semibold ${
          isOnline ? "bg-green" : "bg-red"
        }`}
      >
        <i className={`fi fi-rr-${isOnline ? "wifi" : "wifi-slash"}`}></i>
        <div>{isOnline ? "OnLine" : "OffLine"}</div>
      </div>
    );
  };

  return (
    <>
      {navigator.onLine ? (
        showOnline && <Status isOnline={true} />
      ) : (
        <Status isOnline={false} />
      )}
    </>
  );
};

export default NetworkStatus;
