export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER"

export const setUserDetails = (user) => {
    return {
        type: SET_USER,
        user: user
    }
}

export const getUserDetails = () => {
    return {
        type: GET_USER,
    }
}