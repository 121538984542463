import React from "react";

const AddressTypeButton = (props) => {
  return (
    <button
      type="button"
      className={`px-4 py-2 rounded-md shadow-sm ${props.class}`}
      value={props.text}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default AddressTypeButton;
