import React from "react";
import Footer from "../../components/Main/Footer";
import Logo from "../../components/Onboard/Logo";
// https://www.perplexity.ai/search/284af60d-bcb8-481d-996c-274ff16e215f?s=u
const About = () => {
  return (
    <div className="flex flex-col gap-4 md:max-w-screen-sm lg:max-w-screen-md mx-auto pt-5 overflow-y-scroll px-4">
      <Logo />
      <p className="title">About Us</p>
      <div className="grid grid-cols-2 gap-4 items-center">
        <img
          src={require("../../assets/images/dc5.jpg")}
          alt="dc5"
          className="object-cover"
        />
        <img
          src={require("../../assets/images/dc3.jpg")}
          alt="dc3"
          className="object-cover"
        />
        <img
          src={require("../../assets/images/dc1.jpg")}
          alt="dc1"
          className="object-cover"
        />
        <img
          src={require("../../assets/images/dc2.jpeg")}
          alt="dc2"
          className="object-cover"
        />
        <img
          src={require("../../assets/images/dc4.jpeg")}
          alt="dc4"
          className="object-cover"
        />
        <img
          src={require("../../assets/images/dc6.jpg")}
          alt="dc6"
          className="object-cover"
        />
      </div>
      <div className="flex flex-col gap-5 mt-5">
        <div>
          We are a restaurant that was established in the midst of the COVID-19
          pandemic, with the aim of providing a lifeline to people who were
          unable to leave their homes. We started by offering delivery services,
          which quickly became a permanent part of our business. As in-person
          dining returns, we have expanded our business and opened a cafe where
          we offer Al-Baik like dishes. Our food is inspired by traditional
          recipes and made with fresh, high-quality ingredients.
        </div>
        <div>
          Our goal is to provide our customers with delicious food that they can
          enjoy in the comfort of their own homes. We take pride in our ability
          to adapt to changing circumstances and provide our customers with the
          best possible service. We believe that the long-term health of
          restaurants and other service businesses requires creativity and a
          diversity of revenue streams.
        </div>
        <div>
          Our customers love our food, and we are proud to have received many
          positive reviews. We also provide catering services for various
          functions like anniversaries, marriages, birthdays, and any other kind
          of gatherings or parties.
        </div>
        <div>
          We are planning to offer our franchise to different cities, so that
          more people can enjoy our delicious food.
        </div>
        <div>
          We are committed to providing our customers with the best possible
          experience, and we are always looking for ways to improve our service.
        </div>
        <div>
          Thank you for choosing our restaurant. We look forward to serving you
          soon.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
