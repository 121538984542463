export const GET_DATA = "GET_DATA";
export const SET_DATA = "SET_DATA"

export const setOutletData = (data) => {
    return {
        type: SET_DATA,
        data: data
    }
}

export const getOutletData = () => {
    return {
        type: GET_DATA,
    }
}