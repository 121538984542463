import { toast } from "react-hot-toast";
import { firestore } from "./firebase.config";
import {
  doc,
  getDoc,
  getDocs,
  setDoc,
  collection,
  query,
  where,
} from "firebase/firestore";
import { setUserDetails } from "../context/actions/userActions";
import { setSettings } from "../context/actions/settingsActions";
import { setFAQS } from "../context/actions/faqsActions";
import { COLLECTION } from "./constants";

// GET FIRESTORE DATA - users | app
export const getFirestoreData = async (
  collectionName,
  target,
  setLoading,
  dispatch
) => {
  try {
    setLoading(true);
    const queryRef = doc(firestore, collectionName, target);
    const querySnap = await getDoc(queryRef);
    if (querySnap.exists()) {
      const data = querySnap.data();
      switch (collectionName) {
        case COLLECTION.users:
          dispatch(setUserDetails(data));
          break;
        case COLLECTION.app:
          target === "settings"
            ? dispatch(setSettings(data))
            : dispatch(setFAQS(data));
          break;
        default:
          toast.error(`Invalid CollectionName: ${collectionName}`);
          break;
      }
    } else {
      console.log(`Document Not Found: ${target}`);
    }
  } catch (error) {
    onError(error);
  } finally {
    setLoading(false);
  }
};

export const updateUserState = async (userRef, dispatch) => {
  const userSnap = await getDoc(userRef);
  dispatch(setUserDetails(userSnap.data()));
};

export const onError = (error) => {
  console.log(error);
  toast.error(error?.message);
};

export const getCurrentUser = async () => {
  try {
    const userRef = doc(
      firestore,
      "users",
      localStorage.getItem("phoneNumber")
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) return userSnap.data();
    console.log("User Does Not Exist");
    toast.error("User Does Not Exist");
  } catch (error) {
    onError(error);
  }
};

export const setNotificationToken = async (userID, notificationToken) => {
  try {
    const userRef = doc(firestore, "users", userID);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const token = userSnap.data().notificationToken;
      // Check if token exists & Only update unique/new token
      if (token?.User === notificationToken) return;
      console.log('updating token',token?.User === notificationToken)
      return await setDoc(
        userRef,
        {
          notificationToken: { User: notificationToken },
        },
        { merge: true }
      );
    }
    console.log("User Does Not Exist");
    toast.error("User Does Not Exist");
  } catch (error) {
    onError(error);
  }
};

export const getAdminTokens = async () => {
  try {
    const userRef = collection(firestore, "users");
    const q = query(userRef, where("roles", "array-contains", "Admin"));
    const querySnapshot = await getDocs(q);
    var notificationTokens = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().notificationToken)
        notificationTokens.push(doc.data().notificationToken.Admin);
    });
    return notificationTokens;
  } catch (error) {
    onError(error);
  }
};
