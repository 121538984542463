import React from "react";
import { useNavigate } from "react-router-dom";

const HeaderBack = ({title}) => {
  const navigate = useNavigate()
  return (
    <div className="sticky top-0 bg-grey-light dark:bg-grey-dark py-4 z-20">
      <div onClick={()=>navigate(-1)} className="cursor-pointer">
        <i className="fi fi-rr-angle-left absolute"></i>
      </div>
      <div className="text-center font-raleway font-semibold tracking-wider">{title}</div>
    </div>
  );
};

export default HeaderBack;
