import { actionType } from "../actions/cartActions";

const cartReducer = (state = [], action) => {
  
  switch (action.type) {
    case actionType.ADD_TO_CART:
      // Check if the product already exists in the cart
      const existingProductIndex = state.findIndex(
        (item) => item.pid === action.payload.pid
      );

      if (existingProductIndex !== -1) {
        // Check if the variant already exists in the cart
        const existingVariantIndex = state[
          existingProductIndex
        ].variant.findIndex((item) => item.vid === action.payload.variant._key);

        if (existingVariantIndex !== -1) {
          // Increase the quantity of the variant
          const updatedVariant = {
            ...state[existingProductIndex].variant[existingVariantIndex],
            qty:
              state[existingProductIndex].variant[existingVariantIndex].qty + 1,
          };

          return [
            ...state.slice(0, existingProductIndex),
            {
              ...state[existingProductIndex],
              variant: [
                ...state[existingProductIndex].variant.slice(
                  0,
                  existingVariantIndex
                ),
                updatedVariant,
                ...state[existingProductIndex].variant.slice(
                  existingVariantIndex + 1
                ),
              ],
            },
            ...state.slice(existingProductIndex + 1),
          ];
        } else {
          // Add the variant to the product
          const newVariant = {
            vid: action.payload.variant._key,
            qty: 1,
          };

          return [
            ...state.slice(0, existingProductIndex),
            {
              ...state[existingProductIndex],
              variant: [...state[existingProductIndex].variant, newVariant],
            },
            ...state.slice(existingProductIndex + 1),
          ];
        }
      } else {
        // Add the product to the cart
        const newProduct = {
          pid: action.payload.pid,
          variant: [
            {
              vid: action.payload.variant._key,
              qty: 1,
            },
          ],
        };

        return [...state, newProduct];
      }

    case actionType.REMOVE_FROM_CART:
      // Remove all variants of the product from the cart
      return state.filter((item) => item.pid !== action.payload.pid);

    case actionType.EMPTY_CART:
      // Empty the entire cart
      return [];

    case actionType.INCREASE_QUANTITY:
      // Increase the quantity of the variant
      return state.map((item) => {
        if (item.pid === action.payload.pid) {
          return {
            ...item,
            variant: item.variant.map((variant) => {
              if (variant.vid === action.payload.variant._key) {
                return {
                  ...variant,
                  qty: variant.qty + 1,
                };
              } else {
                return variant;
              }
            }),
          };
        } else {
          return item;
        }
      });

    case actionType.DECREASE_QUANTITY:
      // Decrease the quantity of the variant
      return state
        .map((item) => {
          if (item.pid === action.payload.pid) {
            const updatedVariants = item.variant
              .map((variant) => {
                if (variant.vid === action.payload.variant._key) {
                  return {
                    ...variant,
                    qty: variant.qty - 1,
                  };
                } else {
                  return variant;
                }
              })
              .filter((variant) => variant.qty > 0);

            if (updatedVariants.length > 0) {
              // If there are still variants of the product in the cart, update the variants
              return {
                ...item,
                variant: updatedVariants,
              };
            } else {
              // If there are no variants of the product in the cart, remove the product
              return null;
            }
          } else {
            return item;
          }
        })
        .filter((item) => item !== null);

    default:
      return state;
  }
};

export default cartReducer;
