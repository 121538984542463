export const GET_FAQS = "GET_FAQS";
export const SET_FAQS = "SET_FAQS";

export const setFAQS = (FAQS) => {
  return {
    type: SET_FAQS,
    payload: FAQS,
  };
};

export const getFAQS = () => {
  return {
    type: GET_FAQS,
  };
};
