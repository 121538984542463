import React from "react";
import Footer from "../../components/Main/Footer";
import Logo from "../../components/Onboard/Logo";

const CancellationAndRefund = () => {
  return (
    <div className="legal">
        <Logo />
      <h2 className="title">Refund and Cancellation Policy</h2>
      <p>
        We understand that sometimes things don't go as planned, and you may
        need to cancel or request a refund for your order. Please read our
        policy below to understand how we handle cancellations and refunds.
      </p>
      <h3>Cancellation</h3>
      <ul>
        <li>
          If you need to cancel your order, please do so before it starts to
          prepare.
        </li>
        <li>
          If you cancel your order before it starts to prepare, we will issue a
          credit to your account that you can use for future orders.
        </li>
        <li>
          Once your order is prepared, we cannot issue a refund or credit.
        </li>
      </ul>
      <h3>Return</h3>
      <p>
        We want you to be completely satisfied with your order. If for any
        reason you are not satisfied with your purchase, please read our return
        policy below.
      </p>
      <ul>
        <li>
        You may return your order if there is a mistake from the restaurant resulting in spoiled, damaged, or incorrect food.
        </li>
        <li>
          To initiate a return, please contact us at <a href="mailto:hoteldotcom76@gmail.com">hoteldotcom76@gmail.com</a> or <a href="tel:+917756957834">+91 7756957834</a> with your order number and a description of the issue.
        </li>
        <li>
          We may require photo evidence of the issue before processing your
          return.
        </li>
      </ul>
      <h3>Refund</h3>
      <ul>
        <li>
          If there is an issue with your order, please contact us immediately so
          we can address the issue.
        </li>
        <li>
          If we are unable to fulfill your order due to unforeseen
          circumstances, we will issue a full refund.
        </li>
        <li>
          If you are not satisfied with your order, please contact us within 24
          hours of delivery to discuss a possible refund or credit.
        </li>
      </ul>
      <p>
        Please note that we reserve the right to refuse a refund or credit if we
        believe that the request is fraudulent or if the customer has a history
        of abusing our cancellation and refund policy.
      </p>
      <h3>No Refunds</h3>
      <ul>
        <li>
          We do not offer refunds for orders that have been delivered and
          accepted by the customer, except in cases where the food is spoiled,
          damaged, or incorrect.
        </li>
        <li>
          We do not offer refunds for orders that have been cancelled before the order is prepared.
        </li>
      </ul>
      <p>
        If you have any questions or concerns about our refund and cancellation
        policy, please contact us at <a href="mailto:hoteldotcom76@gmail.com">hoteldotcom76@gmail.com</a> or <a href="tel:+917756957834">+91 7756957834</a>.
      </p>
      <Footer />
    </div>
  );
};

export default CancellationAndRefund;
