import React from "react";

const ActionButton = ({ id, onClick, text, disabled, className }) => {
  return (
    <div className="flex">
      <button
        type="button"
        id={id}
        className={`flex-1 uppercase text-center mx-2 p-4 bg-white dark:bg-black rounded-2xl shadow-lg ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    </div>
  );
};

export default ActionButton;
