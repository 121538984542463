import React from 'react'

const Footer = () => {
  return (
    <div className='text-center font-light mt-52 text-sm font-roboto'>
        By continuing you confirm that you agree with our <u>terms & conditions</u> and <u>privacy policy</u>
    </div>
  )
}

export default Footer