import React from 'react'

const Heading = (props) => {
  return (
    <div className={`font-raleway uppercase font-bold tracking-wider text-center my-10 ${props.class}`}  >
        {props.text}
    </div>
  )
}

export default Heading