export const actionType = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  INCREASE_QUANTITY: "INCREASE_QUANTITY",
  DECREASE_QUANTITY: "DECREASE_QUANTITY",
  EMPTY_CART: "EMPTY_CART",
};

export const addToCart = (pid, variant) => ({
  type: actionType.ADD_TO_CART,
  payload: { pid, variant },
});

export const increaseQuantity = (pid, variant) => ({
  type: actionType.INCREASE_QUANTITY,
  payload: { pid, variant },
});

export const decreaseQuantity = (pid, variant) => ({
  type: actionType.DECREASE_QUANTITY,
  payload: { pid, variant },
});

export const removeFromCart = (pid) => ({
  type: actionType.REMOVE_FROM_CART,
  payload: { pid },
});

export const emptyCart = () => ({
  type: actionType.EMPTY_CART,
});
