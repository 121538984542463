import React, { useEffect, useState } from "react";
// https://www.perplexity.ai/search/5265fbc1-047d-4936-80c3-7c3ecc4aadc7?s=u
const InstallPWAButton = () => {
  const [isInstalled, setIsInstalled] = useState(false);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const checkIfInstalled = async () => {
      if ("getInstalledRelatedApps" in window.navigator) {
        const relatedApps = await window.navigator.getInstalledRelatedApps();
        setIsInstalled(relatedApps.length > 0);
      } else if (window.matchMedia("(display-mode: standalone)").matches) {
        setIsInstalled(true);
      }
    };

    checkIfInstalled();
  }, []);


  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  useEffect(() => {
    console.log(
      `isInstalled: ${isInstalled} | supportsPWA: ${supportsPWA} | deferredPrompt: ${deferredPrompt}`
    );
  }, [isInstalled, supportsPWA, deferredPrompt]);

  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    setSupportsPWA(true);
  });

  const handleInstallClick = () => {
    if (supportsPWA) {
      console.log("clicked");
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <>
      {supportsPWA && !isInstalled && (
        <div className="flex space-x-4 justify-center items-center">
          <button
            id="pwa-install"
            onClick={handleInstallClick}
            className="flex space-x-4 bg-white dark:bg-black px-4 py-2 rounded-lg shadow-md"
          >
            <i className="fi fi-rr-download"></i>
            <div>Install</div>
          </button>
          <div className="text-sm">for better Experience</div>
        </div>
      )}
    </>
  );
};

export default InstallPWAButton;
