export const MainURL = "/main";
export const LoginURL = ["/onboard/with-OTP", "/onboard/verify-otp"];
export const OnboardURL = ["/onboard/name-email", "/onboard/address"];
export const onboarded = localStorage.getItem("onboarded");
export const phoneNumber = localStorage.getItem("phoneNumber");
export const isLoggedIN = localStorage.getItem("isLoggedIN");

export const HOTEL = { lat: 17.5359913, lng: 73.4956538 };

export const COLLECTION = {
  users: "users",
  orders: "orders",
  app: "app",
};

export const ROLE = {
  User: { link: 'https://dc-user.web.app', icon: "user" },
  DeliveryBoy: { link: 'https://dotcom-delivery.web.app', icon: "biking-mountain" },
  Kitchen: { link: 'https://dc-kitchen.web.app', icon: "hat-chef" },
  Admin: { link: 'https://dotcom-admin.web.app', icon: "user-gear" },
  Table: { link: 'https://dotcom-table.web.app', icon: "concierge-bell" },
};

export const OUTLET = [
  { name: "Restaurant", value: "Restaurant", icon: "utensils" },
  { name: "Cafe", value: "Cafe", icon: "hamburger" },
];

export const ORDER_TYPE = [
  { name: "Dine-In", value: "DineIn", icon: "restaurant" },
  { name: "Take Away", value: "TakeAway", icon: "hand-holding-box" },
  { name: "Home Delivery", value: "HomeDelivery", icon: "home-location" },
];

export const FOOD_TYPE = [
  { name: "VEG", value: "veg", color: "text-green", outline: "outline-green" },
  { name: "NONVEG", value: "nonveg", color: "text-red", outline: "outline-red" },
];

export const PAYMENT_METHOD = [
  { name: "Pay Online", value: "PayOnline", icon: "shield-check" },
  { name: "Pay By Cash", value: "PayByCash", icon: "money-bills" },
];

/*
    Pending: The order has been placed but has not yet been processed.
    Processing: The order is being prepared for delivery.
    Out for delivery: The order has been picked up by the delivery person and is on its way to the customer.
    Delivered: The order has been successfully delivered to the customer.
    Cancelled: The order has been cancelled by the customer or the restaurant.
    Refunded: The payment for the order has been refunded to the customer.
    Returned: The customer has returned the order to the restaurant.
    Completed: The order has been successfully processed and delivered to the customer.

    pending - loading
    paid - shield-check
    accepted - text-check
    preparing - dish
    out for delivery - biking-mountain
    delivered - map-marker-check
    completed - circle-check
    cancelled - cross-circle
    returned - undo
    refunded - undo
*/

export const STATUS = {
  Pending: { color: "bg-yellow text-black", icon: "loading" },
  Paid: { color: "bg-green text-white", icon: "shield-check text-white" },
  Accepted: { color: "bg-blue text-white", icon: "text-check text-white" },
  Preparing: { color: "bg-green text-white", icon: "grill text-white" },
  Prepared: { color: "bg-lightgreen text-black", icon: "pot text-black" },
  OutForDelivery: {
    color: "bg-p-orange text-black",
    icon: "biking-mountain text-black",
  },
  Delivered: { color: "bg-green", icon: "map-marker-check" },
  Completed: { color: "bg-green", icon: "check-circle" },
  Cancelled: { color: "bg-grey-dark", icon: "cross-circle" },
  Rejected: { color: "bg-red text-black", icon: "cross-circle text-black" },
  Returned: { color: "bg-purple text-white", icon: "undo text-white" },
  Refunded: {
    color: "bg-grey-dark dark:bg-grey-light text-white dark:text-black",
    icon: "undo text-white dark:text-black",
  },
};
