import React from "react";

const OptionButton = (props) => {
  return (
    <div className="mb-10 rounded-2xl flex justify-start p-4 mx-2 bg-white dark:bg-black shadow-lg clickable" onClick={props.onClick}>
      <div className=" mr-6 ml-2">
        {props.icon}
      </div>
      <div>{props.text}</div>
    </div>
  );
};

export default OptionButton;
