export const GET_ORDER = "GET_ORDER";
export const SET_ORDER = "SET_ORDER"

export const setOrder = (data) => {
    return {
        type: SET_ORDER,
        data: data
    }
}

export const getOrder = () => {
    return {
        type: GET_ORDER,
    }
}