import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const BottomNavigation = () => {
  const isPC = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const location = useLocation().pathname.split("/");
  const [isHome, setIsHome] = useState(true);
  useEffect(() => {
    if (location.length === 2) return setIsHome(true);
    setIsHome(false);
  }, [location]);
  const cart = useSelector((state) => state.cart);

  const NavButton = ({ icon, text, mark, isHome }) => {
    return (
      <div
        className={`relative flex space-x-2 justify-center items-center px-3 py-2 ${
          mark && cart.length && location[2] === text.toLowerCase() && "pr-5"
        }`}
      >
        <i className={`fi fi-rr-${icon}`}></i>
        {(location[2] === text.toLowerCase() || isHome) && (
          <div className="text-sm mt-[-0.15rem]">{text}</div>
        )}
        {mark && cart.length ? (
          <div
            className={`absolute p-1.5 ${
              location[2] === text.toLowerCase()
                ? "bg-white dark:bg-black top-1 right-1"
                : "bg-black dark:bg-p-orange top-0 right-0"
            } rounded-full`}
          ></div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const handleActive = (isActive) => {
    return isActive
      ? "bg-black dark:bg-p-orange text-white dark:text-black rounded-lg shadow-lg"
      : "";
  };

  return (
    <div className="fixed bottom-0 w-full p-2 bg-white dark:bg-black z-50 flex justify-evenly lg:flex-col lg:sticky lg:top-0 lg:left-0 lg:h-screen lg:max-w-[8rem]">
      {isPC && (
        <img
          src={require("../../assets/images/dc-logo.png")}
          alt=""
          className="max-w-[6rem] self-center bg-black p-2 dark:p-0 rounded dark:rounded-none"
        ></img>
      )}
      <NavLink
        end
        to=""
        name="Home"
        className={({ isActive }) => handleActive(isActive)}
      >
        <NavButton text="Home" icon="home" isHome={isHome} />
      </NavLink>
      <NavLink
        to="categories"
        name="Categories"
        className={({ isActive }) => handleActive(isActive)}
      >
        <NavButton text="Categories" icon="apps" />
      </NavLink>
      {/* <NavLink to="offers" name="Offers" className={({ isActive }) => handleActive(isActive)}>
        <NavButton text="Offers" icon="badge-percent" />
      </NavLink> */}
      <NavLink
        to="cart"
        name="Cart"
        className={({ isActive }) => handleActive(isActive)}
      >
        <NavButton text="Cart" icon="shopping-cart" mark={true} />
      </NavLink>
      <NavLink
        to="orders"
        name="Orders"
        className={({ isActive }) => handleActive(isActive)}
      >
        <NavButton text="Orders" icon="shopping-bag" />
      </NavLink>
      <NavLink
        to="profile"
        name="Profile"
        className={({ isActive }) => handleActive(isActive)}
      >
        <NavButton text="Profile" icon="user" />
      </NavLink>
    </div>
  );
};

export default BottomNavigation;
