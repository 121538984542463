import { GET_FAQS, SET_FAQS } from "../actions/faqsActions";

const FAQSReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_FAQS:
      return action.payload;

    case GET_FAQS:
      return state;

    default:
      return state;
  }
};

export default FAQSReducer;
