import { GET_ORDER, SET_ORDER } from "../actions/orderActions";

const orderReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ORDER:
      return state;

    case SET_ORDER:
      return action.data;

    default:
      return state;
  }
};

export default orderReducer;
