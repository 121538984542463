import { lazy } from "react";
import ActionButton from "./ActionButton";
import AddressTypeButton from "./AddressTypeButton";
import AddressWarning from "./AddressWarning";
import Footer from "./Footer";
import Heading from "./Heading";
import InputField from "./InputField";
import Logo from "./Logo";
import OptionButton from "./OptionButton";
import SocialButtons from "./SocialButtons";

const OnboardSharedLayout = lazy(() => import("./OnboardSharedLayout"));

export {
  ActionButton,
  AddressTypeButton,
  AddressWarning,
  Footer,
  Heading,
  InputField,
  Logo,
  OptionButton,
  SocialButtons,
  OnboardSharedLayout,
};
