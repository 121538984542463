import { combineReducers } from "redux";
import userReducer from "./userReducer";
import outletDataReducer from "./outletDataReducer";
import cartReducer from "./cartReducer";
import orderReducer from "./orderReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import SettingsReducer from "./settingsReducer";
import FAQSReducer from "./faqsReducer";

const rootReducer = combineReducers({
  user: userReducer,
  data: outletDataReducer,
  cart: cartReducer,
  order: orderReducer,
  settings: SettingsReducer,
  faqs: FAQSReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "data", "cart", "order", "settings", "faqs"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
