import React from "react";
import { Link } from "react-router-dom";

const Footer = ({showHome=true}) => {
  return (
    <div className="bg-s-white dark:bg-s-black px-4 py-6 flex flex-wrap gap-2 items-center justify-around mt-5">
      {showHome && <Link to="/">Home</Link>}
      <Link to="/about">About</Link>
      <Link to="/contact">Contact</Link>
      <Link to="/terms-and-conditions">Terms & Conditions</Link>
      <Link to="/privacy-policy">Privacy Policy</Link>
      <Link to="/refund-and-cancellation">Refund & Cancellation</Link>
      <Link to="/shipping-and-delivery">Shipping & Delivery</Link>
      <div className="text-sm mt-2 flex flex-wrap md:gap-4 justify-around items-center">
        <div>Hotel Dot Com © 2023 | All Rights Reserved</div>
        <div>
          Developed with 💟 by{" "}
          <a href="https://instagram.com/arshadali.21" target="_blank" rel="noreferrer" className="underline hover-underline">Arshad Ali Kaldane</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
