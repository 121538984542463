import React, { useState } from "react";
import Footer from "../../components/Main/Footer";
import Logo from "../../components/Onboard/Logo";
import { toast } from "react-hot-toast";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const name = form.get("name");
    const email = form.get("email");
    const subject = form.get("subject");
    const message = form.get("message");
    if (
      name.trim().length < 1 ||
      email.trim().length < 1 ||
      subject.trim().length < 1 ||
      message.trim().length < 1
    )
      return toast.error("Please fill all the required fields!");
    emailjs
      .send(
        "service_87lcx2q",
        "template_y1h4yer",
        {
          from_name: name,
          to_name: "Hotel Dot Com",
          from_email: email,
          to_email: "hoteldotcom76@gmail.com",
          from_subject: subject,
          from_message: message,
        },
        "w6LktGd7gPMlAlmuZ"
      )
      .then(
        () => {
          toast.success("Message Sent Successfully");
        },
        (error) => {
          toast.error(error);
        }
      );
    resetForm(form);
  };

  function resetForm(form) {
    for (const [key] of form.entries()) {
      form.delete(key);
    }
    const inputFields = document.querySelectorAll("input");
    inputFields.forEach((input) => (input.value = ""));
    const textArea = document.querySelectorAll("textarea");
    textArea.forEach((input) => (input.value = ""));
  }

  const Section = ({ icon, text, link }) => {
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="flex space-x-2 hover:underline hover:underline-offset-4 hover:decoration-p-black dark:hover:decoration-p-orange"
      >
        <i className={`fi ${icon}`}></i>
        <div>{text}</div>
      </a>
    );
  };
  return (
    <div className="flex flex-col gap-4 md:max-w-screen-sm lg:max-w-screen-md mx-auto pt-5 overflow-y-scroll px-4">
      <Logo />
      <div className="mt-5 flex flex-wrap justify-around gap-2">
        <Section
          icon="fi-brands-whatsapp"
          text="+91 8600807834"
          link="tel:+918600807834"
        />
        <Section
          icon="fi-rr-mobile-button"
          text="+91 7756957834"
          link="tel:+917756957834"
        />
        <Section
          icon="fi-brands-instagram"
          text="hotel_dot_com"
          link="https://instagram.com/hotel_dot_com"
        />
      </div>
      <Section
        icon="fi-rr-marker"
        text="Shop No. 7, Mustaqeem Silver Sand, Sakhar Wadi, Mirjoli, Guhagar Road, Chiplun, Ratnagiri, Pin 415 605, Maharashtra, India"
      />
      {loading && (
        <div className="flex justify-center items-center tracking-widest">
          LOADING MAP...
        </div>
      )}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.4029619587927!2d73.49565377532308!3d17.535991283376724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc20936b552308b%3A0x2db04598161540f8!2sHOTEL%20DOT%20COM!5e0!3m2!1sen!2sin!4v1694962127810!5m2!1sen!2sin"
        title="hotel-dot-com"
        className="border-none"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        onLoad={() => setLoading(false)}
      ></iframe>

      <form
        className="my-5 bg-s-white dark:bg-s-black p-4 flex flex-col gap-5 rounded-md shadow-sm"
        onSubmit={handleSubmit}
        id="contact-form"
      >
        <div className="contactInput">
          <label>Name</label>
          <input
            type="text"
            placeholder="I Am..."
            className="input"
            name="name"
            autoComplete="off"
            required
          />
        </div>
        <div className="contactInput">
          <label>Email</label>
          <input
            type="email"
            placeholder="example@abc.com"
            className="input"
            name="email"
            autoComplete="off"
            required
          />
        </div>
        <div className="contactInput">
          <label>Subject</label>
          <input
            type="text"
            placeholder="My query is to..."
            className="input"
            name="subject"
            autoComplete="off"
            required
          />
        </div>
        <div className="contactInput">
          <label>Message</label>
          <textarea
            type="text"
            placeholder="Brief your Query..."
            className="input"
            name="message"
            autoComplete="off"
            required
          ></textarea>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="flex space-x-2 px-4 py-2 rounded-md shadow-md bg-white dark:bg-p-black border-2 border-p-black dark:border-p-orange"
          >
            <i className="fi fi-rr-paper-plane"></i>
            <div>Send</div>
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default Contact;
