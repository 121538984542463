import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Logo from "../Onboard/Logo";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import InstallPWAButton from "./InstallPWAButton";
import Footer from "./Footer";

const OnboardCarousel = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    const MainURL = "/main";
    const LoginURL = ["/onboard/with-OTP", "/onboard/verify-otp"];
    const onboarded = localStorage.getItem("onboarded");
    const phoneNumber = localStorage.getItem("phoneNumber");
    const isLoggedIN = localStorage.getItem("isLoggedIN");

    const userLoggedIN = isLoggedIN !== null && phoneNumber !== null && user;

    if (onboarded !== null && isLoggedIN === null) {
      // console.log('onboarded but not logged in')
      return navigate(LoginURL[0], { replace: true });
    } else if (onboarded !== null && userLoggedIN) {
      // console.log('onboarded and logged in')
      return navigate(MainURL, { replace: true });
    }
  }, [navigate, user]);

  const isPC = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const DATA = {
    0: {
      title: "Order Food Online",
      body: "Easily browse and select your Favorite Dishes from the Restaurant's Menu and Place your Order Online.",
    },
    1: {
      title: "Get Your Order",
      body: "Track the Progress of your Order and Receive Updates on its Status, ensuring a Smooth and Convenient Experience.",
    },
    2: {
      title: "Bon Appetite",
      body: "Experience a Home-Like feeling while enjoying your Favorite Food from our Menu, whether you choose to Dine In, or have it Delivered.",
    },
  };

  return (
    <>
    <div className="w-screen h-screen flex flex-col items-center gap-6 p-4 text-center overflow-scroll lg:flex-row ">
      {!isPC && <Logo />}
      <Carousel
        autoPlay={true}
        interval={5000}
        emulateTouch={true}
        showStatus={false}
        renderThumbs={() => {}}
        onChange={(index) => setCurrentIndex(index)}
      >
        <img
          src={require("../../assets/images/Asset1.png")}
          alt=""
          className="max-w-screen-sm"
        />
        <img
          src={require("../../assets/images/Asset2.png")}
          alt=""
          className="max-w-screen-sm"
        />
        <img
          src={require("../../assets/images/Asset3.png")}
          alt=""
          className="max-w-screen-sm"
        />
      </Carousel>
      <div className="flex flex-col gap-6 lg:gap-8">
        {isPC && <Logo />}
        <div className="font-roboto font-medium tracking-wider uppercase text-2xl">
          {DATA[currentIndex].title}
        </div>
        <div className="max-w-screen-sm">{DATA[currentIndex].body}</div>
        {currentIndex === 2 && (
          <div className="flex justify-center pt-4">
            <div
              className="flex justify-around items-center space-x-8 px-10 py-4 bg-white dark:bg-black rounded-lg shadow-lg w-fit font-medium"
              onClick={() => {
                localStorage.setItem("onboarded", true);
                navigate("/onboard/with-OTP");
              }}
            >
              <div className="uppercase">Get Started</div>
              <i className="fi fi-rr-sign-in-alt"></i>
            </div>
          </div>
        )}
        <InstallPWAButton />
      </div>
    </div>
      {isPC && <Footer />}
    </>
  );
};

export default OnboardCarousel;
