import React from "react";

const Logo = ({ className }) => {
  return (
    <div className="px-6 pt-14 pb-8 rounded-3xl shadow-xl bg-black lg:flex lg:items-center /lg:pt-0">
      <img
        src={require("../../assets/images/dc-logo.png")}
        alt="dcLogo"
        className={`mt-[-2rem] ${className}`}
      />
    </div>
  );
};

export default Logo;
