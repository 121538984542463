import { lazy } from "react";
import BottomNavigation from "./BottomNavigation";
import Footer from "./Footer";
import Header from "./Header";
import HeaderBack from "./HeaderBack";
import InstallPWAButton from "./InstallPWAButton";
import NetworkStatus from "./NetworkStatus";
import OnboardCarousel from "./OnboardCarousel";

const MainSharedLayout = lazy(() => import("./MainSharedLayout"));
const SharedLayout = lazy(() => import("./SharedLayout"));

export {
  BottomNavigation,
  Footer,
  Header,
  HeaderBack,
  InstallPWAButton,
  NetworkStatus,
  OnboardCarousel,
  MainSharedLayout,
  SharedLayout,
};
