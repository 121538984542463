import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase.config";
import { toast } from "react-hot-toast";
import { getCurrentUser, setNotificationToken } from "./functions";

// https://www.perplexity.ai/search/c1504c60-b5f1-4cbd-b827-a1b9cf236bd3?s=u

export const requestNotificationPermission = async () => {
  try {
    // console.log("Requesting permission...");
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // console.log("Notification permission granted.");
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FCM_PUBLIC_KEY,
      });
      // get current user and store the tokens in firstore. also check for repetitive tokens
      if (token) {
        getCurrentUser()
          .then((user) => {
            if (user.phone) setNotificationToken(user.phone, token);
          })
          .catch((error) => console.log(error));
      } else {
        console.log("Failed to retrieve the Notification Token");
      }
    } else if (permission === "denied") {
      console.log("Notification permission denied!");
      alert(
        "Enable Notifications to get Important Updates.\nTo Enable Notifications:\n1. Open the App in Browser.\n2. Click on Lock icon besides the URL in top left corner.\n3. Enable Notification."
      );
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const onMessageListener = () => {
  try {
    onMessage(messaging, (payload) => {
      console.log("Frontend Notification Received:", payload);
      toast(`${payload.notification.title}\n${payload.notification.body}`);
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendOrderNotification = (userTokens, notification) => {
  try {
    const payload = {
      notification: {
        title: notification.title,
        body: notification.body,
        icon: "https://lh3.googleusercontent.com/pw/AIL4fc-Ni9aaQT3NnJbaB5bFwUgxS8sdfyYlwpUoSfxT1Ca7_cuYq-WSfmKyNgGclzx-LM1pgzdudDEMyZUdndufz7ziQCOyX-N5jLKJIOFo7dVRNy2-bzv2wwpbXQJ5sAypBY7inFeoj5n-Ef5ASy8BGRgA=w995-h995-s-no",
        click_action: notification.click_action,
      },
      registration_ids: userTokens,
    };
    fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers: {
        Authorization: `key=${process.env.REACT_APP_FCM_SERVER_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        console.log("FCM response:", response);
      })
      .catch((error) => {
        console.error("FCM error:", error);
      });
  } catch (error) {
    console.log(error);
  }
};
