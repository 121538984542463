import React from "react";
import Footer from "../../components/Main/Footer";
import Logo from "../../components/Onboard/Logo";

const PrivacyPolicy = () => {
  return (
    <div className="legal">
      <Logo />
      <h2 className="title">Privacy Policy</h2>
      <p>
        We respect the privacy of our users and are committed to protecting it.
        This policy describes the types of information we may collect from you
        or that you may provide when you visit our website and our practices for
        collecting, using, maintaining, protecting, and disclosing that
        information.
      </p>
      <h3>Information We Collect</h3>
      <p>
        We may collect personal information from you such as your name, email
        address, phone number, and delivery address when you place an order on
        our website. We may also collect non-personal information such as your
        IP address, browser type, and operating system.
      </p>
      <h3>How We Use Your Information</h3>
      <p>
        We use the information we collect from you to process your orders,
        communicate with you about your orders, and improve our website and
        services. We may also use your information to send you promotional
        emails or newsletters.
      </p>
      <h3>How We Protect Your Information</h3>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. However, we cannot guarantee
        the security of your personal information transmitted to our website.
      </p>
      <h3>Sharing Your Information</h3>
      <p>
        We may share your personal information with our third-party service
        providers who help us process your orders and deliver your food. We may
        also share your information with our affiliates or business partners for
        marketing purposes.
      </p>
      <h3>Changes to Our Privacy Policy</h3>
      We may update our privacy policy from time to time. We will post any
      changes we make to our privacy policy on this page.
      <h3>Contact Us</h3>
      <p>
        If you have any questions or concerns about our privacy policy, please
        contact us at{" "}
        <a href="mailto:hoteldotcom76@gmail.com">hoteldotcom76@gmail.com</a> or{" "}
        <a href="tel:+917756957834">+91 7756957834</a>.
      </p>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
