import { GET_DATA, SET_DATA } from "../actions/outletDataActions";

const outletDataReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DATA:
      return state;

    case SET_DATA:
      return action.data;

    default:
      return state;
  }
};

export default outletDataReducer;
