import React from 'react'
import Marquee from 'react-fast-marquee'

const AddressWarning = () => {
  return (
    <div className='flex space-x-2 items-center py-2 mb-4'>
      <i className='fi fi-rr-info'></i>
      <Marquee className='text-sm bg-grey-light dark:bg-grey-dark font-roboto tracking-wider' pauseOnClick={true} pauseOnHover={true}>
        Our delivery services are currently limited to <div className='font-medium'>&nbsp;Chiplun City&nbsp; </div> only. We apologize for any inconvenience caused to customers outside of Chiplun. We are continuously working to expand our services to other areas in the future. Thank you for your understanding.
    </Marquee>
    </div>
  )
}

export default AddressWarning